import React from "react";
import {
    Title,
    Form,
    TextInput,
    SaveButton,
    useNotify
} from 'react-admin';
import {Typography, DialogContent, DialogActions} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {ApiClient} from "../app/ApiClient";

export const TeamCreateForm = () => {
    const notify = useNotify();

    const handleSubmit = async (values) => {
        try {
            await ApiClient.post('/teams', values);
            notify("Team Created!", 'success');
        } catch (e) { 
            console.log({e});
            notify(e.message, 'error');
        }
    };

    return (
        <Card>
        <Title title="Create new team"/>
        <CardContent>
            <Typography variant="h6" gutterBottom>Create new team</Typography>

            <Form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextInput 
                        label="Team Name" 
                        source="teamName" 
                        fullWidth
                        required="true"
                    />
                </DialogContent>
                <DialogContent>
                    <TextInput 
                        label="Team Leader (email)" 
                        source="teamLeaderEmail" 
                        fullWidth
                        required="true"
                    />
                </DialogContent>
                <DialogActions>
                    <SaveButton label="Create"/>
                </DialogActions>
            </Form>
        </CardContent>
    </Card>
    )
};
