// in src/repositories/teams.js
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    Edit,
} from 'react-admin';
import {TeamEditForm} from "../components/teamEditForm";
import {TeamCreateForm} from "../components/teamCreateForm";

export const TeamFilter = props => (
    <Filter {...props}>
        <TextInput label="Name" source="name"/>
    </Filter>
);
export const TeamList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
        </Datagrid>
    </List>
);

export const TeamEdit = props => (
    <Edit {...props}>
        <TeamEditForm />
    </Edit>
);
export const TeamCreate = props => (
    <TeamCreateForm {...props}/>
);
