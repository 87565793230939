import React from "react";
import {
  Form,
  BooleanInput,
  TextInput,
  PasswordInput,
  Toolbar,
  SaveButton,
  CheckboxGroupInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  DateInput,
} from "react-admin";
import { Box, Typography } from "@material-ui/core";
import { Countries } from "../resources/countries";
import { Roles } from "../resources/roles";
import { TLRoles } from "../resources/teamleader-roles";

export const UserCreateForm = props => (
  <Form>
    <Box p="1em">
      <Box display="flex">
        <Box flex={2}>
          <Typography variant="h6" gutterBottom>
            User
          </Typography>

          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <TextInput source="userName" fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <PasswordInput source="password" fullWidth />
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <TextInput id source="firstName" fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <TextInput source="lastName" fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <ReferenceInput
                label="Referred By"
                source="referredById"
                reference="users"
              >
                <AutocompleteInput optionText="userName" fullWidth />
              </ReferenceInput>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <TextInput source="email" fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <BooleanInput label="Verified" source="verified" />
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <DateInput source="bornDate" fullWidth />
            </Box>
          </Box>

          <Box flex={1} ml="0.5em">
            <SelectInput
              source="nationality"
              fullWidth
              allowEmpty
              choices={Countries}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <CheckboxGroupInput source="roles" choices={Roles} />
            </Box>
            <Box flex={2} ml="0.5em">
              <BooleanInput
                label="2FA Enabled"
                source="twoFactorVerified"
              />
            </Box>
            <Box flex={2} ml="0.5em">
              <BooleanInput
                label="KYC Registered"
                source="KYCRegistered"
              />
            </Box>
            <Box flex={2} ml="0.5em">
              <BooleanInput
                label="KYC High Registered"
                source="KYCHighRegistered"
              />
            </Box>
            <Box flex={2} ml="0.5em">
              <BooleanInput
                label="Disable Rewards"
                source="disabledRewards"
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <ReferenceInput
                label="Team"
                source="teamId"
                reference="teams"
                allowEmpty
              >
                <SelectInput optionText="name" fullWidth />
              </ReferenceInput>
            </Box>
            <Box flex={1} ml="0.5em">
              <BooleanInput label="Team Leader" source="isTeamLeader" />
            </Box>
            <Box flex={1} ml="0.5em">
              <SelectInput
                source="teamLeaderRole"
                fullWidth
                allowEmpty
                choices={TLRoles}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>

    <Toolbar>
      <Box display="flex" justifyContent="space-between" width="100%">
        <SaveButton
          saving={props.saving}
        ></SaveButton>
      </Box>
    </Toolbar>
  </Form>
);
