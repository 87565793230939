import React from "react";
import {
    Title,
    Form,
    TextInput,
    SaveButton,
    useNotify
} from 'react-admin';
import {Typography, DialogContent, DialogActions} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TransformIcon from '@mui/icons-material/Transform';

import {ApiClient} from "../app/ApiClient";

export const ConvertAllProductsToBalance = () => {
    const notify = useNotify();

    const handleSubmit = async (values) => {
        try {
            const { success } = await ApiClient.post('/investments/shutdown/' + values.id, {});
            if(success) {
                notify("All UserId " + values.id + " products converted to balance!", 'success');
            }
        } catch (e) { 
            notify(e.message, 'error');
        }
    };

    return (
        <Card>
            <Title title="Convert all Products to Balance"/>
            <CardContent>
                <Typography variant="h6" gutterBottom>Convert all Product of userId to Balance</Typography>
                <Form onSubmit={handleSubmit}>
                    <DialogContent>
                        <TextInput 
                            label="User Id" 
                            source="id" 
                            fullWidth
                            required="true"
                        />
                    </DialogContent>
                    <DialogActions>
                        <SaveButton label="Convert" icon={<TransformIcon />}/>
                    </DialogActions>
                </Form>
            </CardContent>
        </Card>
    )
};
