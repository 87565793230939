import {checkUserRole} from "./userProvider";

const URL = process.env.REACT_APP_API_BASE_URL;

export const authProvider = {
    login: async ({ username, password }) => {
        const apiKey = process.env.REACT_APP_API_KEY;

        const request = new Request(`${URL}/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ username: username, password }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
            }),
        });
        const res = await fetch(request);
        const body = await res.json();
        if (!res.ok) {
            throw new Error( body.message || 'Network error');
        }

        const token = body.access_token;
        const ttl = body.expires_in;
        authProvider.storeToken(token, ttl);

        await checkUserRole();

    },

    logout: async () => {
        const token = authProvider.getToken();
        const apiKey = process.env.REACT_APP_API_KEY;

        const request = new Request(`${URL}/auth/logout`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            },{
                'Authorization': `Bearer ${token}`,
                'x-api-key': apiKey,
            }),
        });
        await fetch(request);
        authProvider.removeToken();
    },

    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            authProvider.removeToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    checkAuth: () => {
        return authProvider.getToken()
            ? Promise.resolve()
            : Promise.reject();
    },

    getPermissions: () => Promise.resolve(),

    storeToken: (token, ttl) => {
        const now = new Date();

        const item = {
            value: token,
            expiry: now.getTime() + ttl,
        };

        localStorage.setItem('auth', JSON.stringify(item))
    },

    getToken: () => {
        const itemStr = localStorage.getItem('auth');

        if (!itemStr) {
            return null;
        }

        let item;

        try {
            item = JSON.parse(itemStr);
        } catch(e) {
            return null;
        }

        const now = new Date();

        if (now.getTime() > item.expiry) {
            authProvider.removeToken();
            return null;
        }

        return item.value
    },

    removeToken() {
        localStorage.removeItem('auth');
    }
};

