// in src/repositories/users.js
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Edit,
    TextInput,
    EmailField,
    BooleanField,
    ChipField,
    SingleFieldList,
    Create,
    Filter,
} from 'react-admin';

import {TextArrayField} from "../components/textArrayField";
import {UserEditForm} from "../components/userEditForm";
import {UserCreateForm} from "../components/userCreateForm";

export const UserFilter = props => (
    <Filter {...props}>
        <TextInput label="User name" source="userName" />
        <TextInput label="First name" source="firstName" />
        <TextInput label="Last name" source="lastName" />
        <TextInput label="Email" source="email" />
    </Filter>
);
export const UserList = props => (
    <List {...props} filters={<UserFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="userName" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <TextArrayField source="roles">
                <SingleFieldList>
                    <ChipField source="id" />
                </SingleFieldList>
            </TextArrayField>
            <BooleanField source="verified" />
        </Datagrid>
    </List>
);

export const UserEdit = props => (
    <Edit {...props}>
        <UserEditForm />
    </Edit>
);
export const UserCreate = props => (
    <Create {...props}>
        <UserCreateForm/>
    </Create>
  );
