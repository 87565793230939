import React, { useEffect, useState } from "react";
import Card from '@material-ui/core/Card';
import {Typography, DialogContent, DialogActions} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import BackspaceIcon from '@mui/icons-material/Backspace';

import {
    Title,
    Form,
    SaveButton,
    SelectInput,
    useNotify
} from 'react-admin';
import {ApiClient} from "../app/ApiClient";


export const RollbackReleaseRewardPage = () => {
    const notify = useNotify();
    const [products, setProducts] = useState();
    const [isLoadingProducts, setIsLoadingProducts] = useState(true);
    const [isErrorProducts, setIsErrorProducts] = useState(false);

    const handleSubmit = async (values) => {
        try {
            await ApiClient.post('/rewards/reward/release/rollback', values);
            notify('Reward rollback release created!', 'success');
        } catch (e) {
            notify(e.message, 'error');
        }
    };

    useEffect(() => {
        (async() => {
            try {
                const products = await ApiClient.get('/products');
                setIsLoadingProducts(false);
                setProducts(products.data);
            } catch (e) {
                notify(e.message, 'error');
                setIsErrorProducts(true);
            } finally {
                setIsLoadingProducts(false);
            }
        })();
    }, [notify]);

    if(isErrorProducts) {
        return <></>
    }

    if(isLoadingProducts) {
        return <>Loading...</>
    }

    return (
        <Card>
            <Title title="Rollback Release Reward"/>
            <CardContent>
                <Typography variant="h6" gutterBottom>Rollback Release Reward</Typography>
                <Form onSubmit={handleSubmit}>
                    <DialogContent>
                        <SelectInput optionText="name" choices={products} source="productId"  required="true"/>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton label="Rollback" icon={<BackspaceIcon />}/>
                    </DialogActions>
                </Form>
            </CardContent>
        </Card>
    )
};
