import {
    Datagrid,
    List,
    TextField,
    DateField,
    BooleanField,
    Filter,
    TextInput,
    BooleanInput,
    DateTimeInput
} from "react-admin";
import * as React from "react";

export const TeamTransactionsFilter = props => (
    <Filter {...props}>
        <TextInput source="id" />
        <TextInput source="method" />
        <TextInput label="User name" source="userName" />
        <TextInput label="Team name" source="teamName" />
        <BooleanInput source="pending"/>
        <DateTimeInput source="fromDate" />
        <DateTimeInput source="toDate" />
    </Filter>
);

export const TeamTransactionsList = props => (
    <List {...props} filters={<TeamTransactionsFilter/>} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="method" />
            <TextField label="User" source="relUser.userName" sortable={false} />
            <TextField label="Team" source="team.name" sortable={false} />
            <DateField label="Date" source="timestamp" showTime />
            <DateField label="UpdatedDate" source="lastChangedDateTime" showTime />
            <BooleanField source="pending" />
        </Datagrid>
    </List>
);
