// in src/App.js
import * as React from "react";
import {Admin, Resource, fetchUtils, CustomRoutes} from 'react-admin';
import {UserList, UserEdit, UserCreate} from '../repositories/users';
import {TeamList, TeamEdit, TeamCreate} from '../repositories/teams';
import {Dashboard} from '../Dashboard';
import crudProvider from '@fusionworks/ra-data-nest-crud'
import {ProductCreate, ProductEdit, ProductList} from "../repositories/products";
import {authProvider} from "../auth/authProvider";
import {AppLayout} from "./AppLayout";
import {TeamTransactionsList} from "../repositories/teamTransactions";
import { TransferTransactionsList } from "../repositories/transferTransactions";
import { NoTransferTransactionsList } from "../repositories/noTransferTransactions";
import {InvestmentTransactionsList} from "../repositories/investmentTransactions";
import {PlatformTransactionsList} from "../repositories/platformTransactions";
import {ContributionTransactionsList} from "../repositories/contributionTransactions";
import {RewardTransactionsList} from "../repositories/rewardTransactions";
import {SwapTransactionsList} from "../repositories/swapTransactions";
import {ProductAccountEdit, ProductAccountsList} from "../repositories/productAccounts";
import {PlatformShow, PlatformsList} from "../repositories/platforms";
import { Route } from 'react-router-dom';
import {CreateRewardPage} from "../components/createRewardPage";
import {ReleaseRewardPage} from "../components/releaseRewardPage";
import {ReleaseSwapPage} from "../components/releaseSwapPage";
import {ProductContributionsList} from "../repositories/productContributions";
import {ChangeNewReferredTeamPage} from "../components/changeNewReferredTeamPage"
import {ChangeTransferUserPage} from "../components/changeTransferUser";
import {RollbackReleaseRewardPage} from "../components/rollbackReleaseRewardPage";
import {ConvertAllProductsToBalance} from "../components/convertAllProductsToBalance";

require('dotenv').config();

const dataProvider = crudProvider(process.env.REACT_APP_API_BASE_URL, (url, options = {}) => {
    const token = authProvider.getToken();
    const apiKey = process.env.REACT_APP_API_KEY;

    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'x-api-key': apiKey,
        });
    }
    options.headers.set('Authorization', `Bearer ${token}`);

    return fetchUtils.fetchJson(url, options, (options) => {
        console.log(options)
    });
});

const App = () => (
    <Admin
        authProvider={authProvider}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        layout={AppLayout}
        >
        <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate}/>
        <Resource name="teams/transactions" list={TeamTransactionsList}/>
        <Resource name="transfers/transactions" list={TransferTransactionsList} />
        <Resource name="transfers/nointransactions" list={NoTransferTransactionsList} />
        <Resource name="transfers/noouttransactions" list={NoTransferTransactionsList} />
        <Resource name="investments/transactions" list={InvestmentTransactionsList}/>
        <Resource name="platform/transactions" list={PlatformTransactionsList}/>
        <Resource name="contributions/transactions" list={ContributionTransactionsList}/>
        <Resource name="rewards/transactions" list={RewardTransactionsList}/>
        <Resource name="swaps/transactions" list={SwapTransactionsList}/>
        <Resource name="teams" list={TeamList} edit={TeamEdit} create={TeamCreate}/>
        <Resource name="products" list={ProductList} edit={ProductEdit} create={ProductCreate}/>
        <Resource name="product-accounts" list={ProductAccountsList} edit={ProductAccountEdit}/>
        <Resource name="product-contributions" list={ProductContributionsList}/>
        <Resource name="platforms" list={PlatformsList} show={PlatformShow}/>
        <CustomRoutes>
            <Route exact path="/createReward" element={<CreateRewardPage />} />
            <Route exact path="/releaseReward" element={<ReleaseRewardPage />}/>
            <Route exact path="/releaseSwaps" element={<ReleaseSwapPage />}/>
            <Route exact path="/changeTransferTransactionUser" element={<ChangeTransferUserPage />}/>
            <Route exact path="/changeToNewReferredTeam" element={<ChangeNewReferredTeamPage />}/>
            <Route exact path="/convertAllProductsToBalance" element={<ConvertAllProductsToBalance />}/>
            <Route exact path="/rollback-release-reward" element={<RollbackReleaseRewardPage />}/>
        </CustomRoutes>
    </Admin>
);

export default App;
