import React from "react";
import {
  Form,
  BooleanInput,
  TextInput,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
  CheckboxGroupInput,
  SelectInput,
  Datagrid,
  TextField,
  ArrayField,
  EditButton,
  NumberField,
  PasswordInput,
  BooleanField,
  DateInput,
  DateTimeInput,
} from "react-admin";
import { Box, Typography } from "@material-ui/core";

import { Countries } from "../resources/countries";
import { Roles } from "../resources/roles";
import { TLRoles } from "../resources/teamleader-roles";

export const UserEditForm = props => (
  <Form>
    <Box p="1em">
      <Box display="flex">
        <Box flex={2}>
          <Typography variant="h6" gutterBottom>
            User
          </Typography>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <TextInput source="userName" fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <PasswordInput
                label="Set new password"
                source="password"
                fullWidth
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <TextInput source="firstName" fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <TextInput source="lastName" fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <TextInput
                label="Referred By"
                source="referredBy.email"
                fullWidth
              /> 
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <TextInput source="email" fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <BooleanInput label="Verified" source="verified" />
            </Box>
            <Box flex={1} ml="0.5em">
              <BooleanInput label="Blocked" source="blocked" />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <CheckboxGroupInput source="roles" choices={Roles} />
            </Box>
            <Box flex={2} ml="0.5em">
              <BooleanInput
                label="2FA Enabled"
                source="twoFactorVerified"
              />
            </Box>
            <Box flex={2} ml="0.5em">
              <BooleanInput
                label="KYC Registered"
                source="KYCRegistered"
              />
            </Box>
            <Box flex={2} ml="0.5em">
              <BooleanInput
                label="KYC High Registered"
                source="KYCHighRegistered"
              />
            </Box>
            <Box flex={2} ml="0.5em">
              <BooleanInput
                label="Disable Rewards"
                source="disabledRewards"
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <TextInput
                label="Team"
                source="team.name"
                fullWidth
                disabled
              />
            </Box>
            <Box flex={1} ml="0.5em">
              <BooleanInput label="Team Leader" source="isTeamLeader" />
            </Box>
            <Box flex={1} ml="0.5em">
              <SelectInput
                source="teamLeaderRole"
                fullWidth
                allowEmpty
                choices={TLRoles}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <TextInput source="createdBy" fullWidth disabled />
            </Box>
            <Box flex={1} ml="0.5em">
              <TextInput source="lastChangedBy" fullWidth disabled />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <DateInput source="bornDate" fullWidth />
            </Box>
            <Box flex={1}>
              <DateTimeInput
                source="createDateTime"
                showTime
                fullWidth
                disabled
              />
            </Box>
            <Box flex={1} ml="0.5em">
              <DateTimeInput
                source="lastChangedDateTime"
                showTime
                fullWidth
                disabled
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box flex={1} ml="0.5em">
        <SelectInput
          source="nationality"
          fullWidth
          allowEmpty
          choices={Countries}
        />
      </Box>
      <Box display="flex">
        <Box flex={2}>
          <Typography variant="h6" gutterBottom>
            Account
          </Typography>

          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <TextInput
                label="Ethereum Balance"
                source="account.balance"
                fullWidth
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={2}>
          <Typography variant="h6" gutterBottom>
            Product Accounts
          </Typography>

          <ArrayField {...props} source="productAccounts">
            <Datagrid>
              <TextField source="id" />
              <TextField label="Product" source="product.name" />
              <NumberField
                source="contribution"
                options={{ maximumFractionDigits: 20 }}
              />
              <NumberField
                source="blockedContribution"
                options={{ maximumFractionDigits: 20 }}
              />
              <NumberField
                source="balance"
                options={{ maximumFractionDigits: 20 }}
              />
              <NumberField
                source="referrerBalance"
                options={{ maximumFractionDigits: 20 }}
              />
              <NumberField
                source="investment"
                options={{ maximumFractionDigits: 20 }}
              />
              <BooleanField
                source="compoundInterest"
                options={{ maximumFractionDigits: 20 }}
              />
              <EditButton basePath="/product-accounts" />
            </Datagrid>
          </ArrayField>
        </Box>
      </Box>
    </Box>

    <Toolbar>
      <Box display="flex" justifyContent="space-between" width="100%">
        <SaveButton
          saving={props.saving}
        />
        <DeleteWithConfirmButton record={props.record} />
      </Box>
    </Toolbar>
  </Form>
);
