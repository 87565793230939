import React from "react";
import {
    Form,
    TextInput,
    Toolbar,
    SaveButton,
    DeleteWithConfirmButton,
    DateTimeInput,
    NumberInput,
    Datagrid, TextField, ArrayField
} from 'react-admin';

import {Box, Typography} from '@material-ui/core';

export const TeamEditForm = props => (
    <Form>
        <Box p="1em">
            <Box display="flex">
                <Box flex={2}>
                    <Typography variant="h6" gutterBottom>Team details</Typography>

                    <Box display="flex" alignItems="center">
                        <Box flex={1}>
                            <TextInput source="name" fullWidth/>
                        </Box>
                        <Box flex={1} ml="0.5em">
                            <TextInput label="Team Leader" source="teamLeader.userName" fullWidth disabled/>
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Box flex={1}>
                            <TextInput source="createdBy" fullWidth disabled/>
                        </Box>
                        <Box flex={1} ml="0.5em">
                            <TextInput source="lastChangedBy" fullWidth disabled/>
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Box flex={1}>
                            <DateTimeInput source="createDateTime" fullWidth disabled/>
                        </Box>
                        <Box flex={1} ml="0.5em">
                            <DateTimeInput source="lastChangedDateTime" fullWidth disabled/>
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Box flex={1}>
                            <NumberInput source="totalReward" fullWidth disabled/>
                        </Box>
                    </Box>
                    <Typography variant="h6" gutterBottom>Team members</Typography>
                    <Box display="flex">
                        <Box flex={1}>
                            <ArrayField {...props} source="users">
                                <Datagrid>
                                    <TextField label="Username" source="userName"/>
                                    <TextField label="First name" source="firstName"/>
                                    <TextField label="Last name" source="lastName"/>
                                    <TextField label="Email" source="email"/>
                                </Datagrid>
                            </ArrayField>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>

        <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton saving={props.saving}/>
                <DeleteWithConfirmButton record={props.record}/>
            </Box>
        </Toolbar>
    </Form>
);
