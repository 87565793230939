import {
  List,
  Filter,
  TextInput,
  NumberInput,
  BooleanInput,
  DateTimeInput,
} from "react-admin";
import * as React from "react";
import { SwapTransactionsGrid } from "../components/swapTransactionsGrid";

export const SwapTransactionsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="method" />
    <TextInput source="userName" />
    <TextInput source="productName" />
    <NumberInput source="value" />
    <TextInput source="currency" />
    <NumberInput source="usdValue" />
    <BooleanInput source="pending" />
    <BooleanInput source="cancelled" />
    <DateTimeInput source="fromDate" />
    <DateTimeInput source="toDate" />
  </Filter>
);

export const SwapTransactionsList = (props) => (
  <List
    {...props}
    filters={<SwapTransactionsFilter />}
    bulkActionButtons={false}
  >
    <SwapTransactionsGrid />
  </List>
);
