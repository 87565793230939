import React from "react";
import Card from '@material-ui/core/Card';
import {Typography, DialogContent, DialogActions} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {
    Title,
    Form,
    SaveButton,
    TextInput,
    useNotify
} from 'react-admin';
import {ApiClient} from "../app/ApiClient";

export const ChangeTransferUserPage = () => {
    const notify = useNotify();

    const handleSubmit = async (values) => {
        try {
            await ApiClient.patch('/transfers/transactions/' + values.transactionId + '/set-user', values);
            notify('User changed successfully!', 'success');
        } catch (e) {
            console.log({e});
            notify(e.message, 'error');
        }
    };

    return (
        <Card>
            <Title title="Change transfer transaction user"/>
            <CardContent>
                <Typography variant="h6" gutterBottom>Change transfer transaction user</Typography>
                <Form onSubmit={handleSubmit}>
                    <DialogContent>
                        <TextInput label="Transaction ID" source="transactionId" fullWidth required="true"/>
                        <TextInput label="Target User (User Name)" source="targetUser" fullWidth required="true"/>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton label="Change" icon={<ChangeCircleIcon />} />
                    </DialogActions>
                </Form>
            </CardContent>
        </Card>
    )
};
