import * as React from 'react';
import { List, Box } from '@material-ui/core';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MoneyIcon from '@material-ui/icons/Money';
import PageviewIcon from '@material-ui/icons/Pageview';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {authProvider} from "../auth/authProvider";

export const AppMenu = ({ onMenuClick, logout }) => {
    if (!authProvider.getToken()) {
      return (null)
    }
    return (
        <Box>
            <Box mt="2em">
                <List component="nav">
                    <DashboardMenuItem onClick={onMenuClick}/>
                    <MenuItemLink
                        to="/users"
                        primaryText="Users"
                        leftIcon={<PersonIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/teams"
                        primaryText="Teams"
                        leftIcon={<GroupIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/products"
                        primaryText="Products"
                        leftIcon={<ShoppingBasketIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/product-accounts"
                        primaryText="Product Accounts"
                        leftIcon={<AccountTreeIcon />}
                        onClick={onMenuClick}
                    />
                </List>
            </Box>
            <Box mt="2em">
                <List component="nav">
                    <MenuItemLink
                        to="/contributions/transactions"
                        primaryText="Contribution Transactions"
                        leftIcon={<ReceiptIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/investments/transactions"
                        primaryText="Investment Transactions"
                        leftIcon={<ReceiptIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/platform/transactions"
                        primaryText="Platform Transactions"
                        leftIcon={<ReceiptIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/rewards/transactions"
                        primaryText="Reward Transactions"
                        leftIcon={<ReceiptIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/swaps/transactions"
                        primaryText="Swap Transactions"
                        leftIcon={<ReceiptIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/teams/transactions"
                        primaryText="Team Transactions"
                        leftIcon={<ReceiptIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/transfers/transactions"
                        primaryText="Transfer Transactions"
                        leftIcon={<ReceiptIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/transfers/nointransactions"
                        primaryText="No Transfer In Transactions"
                        leftIcon={<ReceiptIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/transfers/noouttransactions"
                        primaryText="No Transfer Out Transactions"
                        leftIcon={<ReceiptIcon />}
                        onClick={onMenuClick}
                    />
                </List>
            </Box>
            <Box mt="2em">
                <List component="nav">
                    <MenuItemLink
                        to="/createReward"
                        primaryText="Create Reward"
                        leftIcon={<MoneyIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/releaseReward"
                        primaryText="Release Reward"
                        leftIcon={<MoneyIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/releaseSwaps"
                        primaryText="Release Swaps"
                        leftIcon={<MoneyIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/changeTransferTransactionUser"
                        primaryText="Change Transfer User"
                        leftIcon={<PersonAddIcon />}
                        onClick={onMenuClick}
                    />
                </List>
            </Box>
            <Box mt="2em">
                <List component="nav">
                    <MenuItemLink
                        to="/changeToNewReferredTeam"
                        primaryText="Change to new referred team"
                        leftIcon={<GroupIcon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to="/convertAllProductsToBalance"
                        primaryText="Convert all Products to Balance"
                        leftIcon={<ShoppingBasketIcon />}
                        onClick={onMenuClick}
                    />
               </List>
            </Box>
            <Box mt="2em">
                <List component="nav">
                    <MenuItemLink
                        to="/product-contributions"
                        primaryText="Product Contributions"
                        leftIcon={<AttachMoneyIcon />}
                        onClick={onMenuClick}
                    />
                </List>
            </Box>
            <Box mt="2em">
                <List component="nav">
                    <MenuItemLink
                        to="/rollback-release-reward"
                        primaryText="Rollback Release Reward"
                        leftIcon={<MoneyIcon />}
                        onClick={onMenuClick}

                    />
                </List>
            </Box>
            <Box mt="2em">
                <List component="nav">
                    <MenuItemLink
                        to="/platforms"
                        primaryText="Platform"
                        leftIcon={<PageviewIcon />}
                        onClick={onMenuClick}

                    />
                </List>
            </Box>
        </Box>
    );
};
