import {authProvider} from "./authProvider";

async function checkUserRole() {
    const token = authProvider.getToken();
    const apiKey = process.env.REACT_APP_API_KEY;

    const request = new Request(`${process.env.REACT_APP_API_BASE_URL}/auth/user`, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'x-api-key': apiKey,
        }),
    });

    const res = await fetch(request);
    const body = await res.json();

    if (!res.ok) {
        authProvider.removeToken();
        throw new Error( body.message || 'Network error');
    }
    const roles = body.roles;

    if (!roles.includes("ADMIN")) {
        throw new Error("Non user admins can't access the admin panel");
    }
}

export { checkUserRole };
