import {Datagrid, List, TextField, NumberField, Show, SimpleShowLayout} from "react-admin";
import * as React from "react";

export const PlatformsList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField source="id"/>
        </Datagrid>
    </List>
);

export const PlatformShow = props => (
    <Show {...props} bulkActionButtons={false}>
        <SimpleShowLayout>
            <NumberField source="monthlyBalance" options={{maximumFractionDigits: 20}}/>
            <NumberField source="houseBalance" options={{maximumFractionDigits: 20}}/>
        </SimpleShowLayout>
    </Show>
);