import {
    Title,
    Form,
    TextInput,
    SaveButton,
    useNotify
} from 'react-admin';
import React from "react";
import {Typography, DialogContent, DialogActions} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

import {ApiClient} from "../app/ApiClient";

export const ChangeNewReferredTeamPage = () => {
    const notify = useNotify();

    const handleSubmit = async (values) => {
        try {
            if (!values.hasOwnProperty('entire')) {
                values.entire = false;
            }

            if (!values.hasOwnProperty('userEmail') || !values.hasOwnProperty('targetEmail')) {
                notify('Please fill all the form data', 'error');
                return;
            }

            await ApiClient.post('/users/change-new-referred-team', values);
            notify("User changed to new referred's team!", 'success');
        } catch (e) {
            notify(e.message, 'error');
        }
    };

    return (
        <Card>
            <Title title="Change to new referred team"/>
            <CardContent>
                <Typography variant="h6" gutterBottom>Change to new referred team</Typography>
                <Form onSubmit={handleSubmit}>
                    <DialogContent>
                        <TextInput 
                            label="Source user (email)" 
                            source="userEmail" 
                            fullWidth
                            required="true"
                        />
                        <p>Move other to New referred's team (email)</p>
                        <TextInput 
                            label="New referred user (email)" 
                            source="targetEmail" 
                            fullWidth
                            required="true"
                        />
                    </DialogContent>
                    <DialogActions>
                        <SaveButton label="Change" icon={<ChangeCircleIcon />} />
                    </DialogActions>
                </Form>
            </CardContent>
        </Card>
    )
};
