import {
    Datagrid,
    List,
    TextField,
    NumberField,
    DateField,
    BooleanField,
    Filter,
    TextInput,
    NumberInput,
    BooleanInput,
    DateTimeInput,
    SelectInput,
} from "react-admin";
import * as React from "react";

export const TransferTransactionsFilter = props => (
    <Filter {...props}>
        <TextInput source="id" />
        <TextInput source="method" />
        <TextInput label="Account ID" source="accountId" />
        <TextInput source="userName" />
        <NumberInput source="value" />
        <SelectInput source="currency" choices={[
            { id: 'ETH', name: 'ETH' },
            { id: 'BTC', name: 'BTC' },
            { id: 'USD', name: 'USD' },
        ]}/>
        <NumberInput source="usdValue" />
        <TextInput source="transactionHash" />
        <TextInput source="wallet" />
        <NumberInput source="gascomission" />
        <DateTimeInput source="fromDate" />
        <DateTimeInput source="toDate" />
        <BooleanInput source="pending"/>
        <NumberInput source="chainId"/>
    </Filter>
);

/*export const TransferTransactionsEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id"/>
            <TextField source="method"/>
            <TextField label="User" source="relAccount.user.userName"/>
            <DateField source="timestamp" showTime/>
            <DateField label="UpdatedDate" source="lastChangedDateTime" showTime />
            <NumberInput source="value"/>
            <SelectInput source="currency" choices={[
                { id: 'ETH', name: 'ETH' },
                { id: 'BTC', name: 'BTC' },
                { id: 'USD', name: 'USD' },
            ]}/>
            <NumberInput source="usdValue"/>
            <TextInput source="transactionHash"/>
            <TextInput source="wallet"/>
            <NumberInput source="gascomission"/>
            <BooleanInput source="pending"/>
        </SimpleForm>
    </Edit>
);

export const TransferTransactionsCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="method"/>
            <ReferenceInput label="User" source="userId" reference="users" perPage={8000}>
                <AutocompleteInput optionText="userName" fullWidth/>
            </ReferenceInput>
            <DateTimeInput source="timestamp" showTime/>
            <DateField label="UpdatedDate" source="lastChangedDateTime" showTime />
            <NumberInput source="value"/>
            <SelectInput source="currency" choices={[
                { id: 'ETH', name: 'ETH' },
                { id: 'BTC', name: 'BTC' },
                { id: 'USD', name: 'USD' },
            ]}/>
            <NumberInput source="usdValue"/>
            <TextInput source="transactionHash"/>
            <TextInput source="wallet"/>
            <NumberInput source="gascomission"/>
            <BooleanInput source="pending"/>
        </SimpleForm>
    </Create>
);*/

export const TransferTransactionsList = props => (
    <List {...props} filters={<TransferTransactionsFilter/>} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <DateField label="Date" source="timestamp" showTime />
            <BooleanField source="pending" />
            <TextField source="method" />
            <NumberField label="Value" source="value" options={{maximumFractionDigits: 20}} />
            <NumberField label="USD Value" source="usdValue" options={{maximumFractionDigits: 20}} />
            <TextField label="User" source="relAccount.user.userName" sortable={false} />
            <TextField label="chainnId" source="chainId" />
            <TextField source="transactionHash" />
            <TextField source="wallet" />
            <NumberField source="gascomission" options={{maximumFractionDigits: 20}} />
            <TextField label="Currency" source="currency" />
            <DateField label="UpdatedDate" source="lastChangedDateTime" showTime />
        </Datagrid>
    </List>
);
