// in src/repositories/productAccounts.js
import * as React from "react";
import {
    Datagrid,
    Edit,
    List,
    SimpleForm,
    TextField,
    NumberField,
    NumberInput,
    BooleanField,
    BooleanInput,
} from "react-admin";

export const ProductAccountsList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField label="Product" source="product.name"/>
            <TextField label="User" source="user.userName"/>
            <NumberField source="balance" options={{maximumFractionDigits: 20}}/>
            <NumberField source="referrerBalance" options={{maximumFractionDigits: 20}}/>
            <NumberField source="contribution" options={{maximumFractionDigits: 20}}/>
            <NumberField source="blockedContribution" options={{maximumFractionDigits: 20}}/>
            <NumberField source="investment" options={{maximumFractionDigits: 20}}/>
            <BooleanField source="compoundInterest"/>
        </Datagrid>
    </List>
);
export const ProductAccountEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField label="Product name" source="product.name"/>
            <TextField label="User" source="user.userName"/>
            <NumberInput source="investment"/>
            <NumberInput source="contribution"/>
            <NumberInput source="blockedContribution"/>
            <NumberInput source="balance"/>
            <NumberInput source="referrerBalance"/>
            <BooleanInput source="compoundInterest"/>
        </SimpleForm>
    </Edit>
);

