import React, { useEffect, useState } from "react";
import Card from '@material-ui/core/Card';
import {Typography, DialogContent, DialogActions} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import {
    Title,
    Form,
    NumberInput,
    SaveButton,
    SelectInput,
    useNotify,
} from 'react-admin';

import {ApiClient} from "../app/ApiClient";

export const CreateRewardPage = () => {
    const notify = useNotify();
    const [products, setProducts] = useState();
    const [isLoadingProducts, setIsLoadingProducts] = useState(true);
    const [isErrorProducts, setIsErrorProducts] = useState(false);
    const handleSubmit = async (values) => {
        try {
            await ApiClient.post('/rewards/reward', values);
            notify('Reward created!', 'success');
        } catch (e) {
            notify('Failed creating reward', 'error');
        }
    };
    useEffect(() => {
        (async() => {
            try {
                const products = await ApiClient.get('/products');
                setIsLoadingProducts(false);
                setProducts(products.data);
            } catch (e) {
                notify(e.message, 'error');
                setIsErrorProducts(true);
            } finally {
                setIsLoadingProducts(false);
            }
        })();
    }, [notify]);

    if(isErrorProducts) {
        return <></>
    }

    if(isLoadingProducts) {
        return <>Loading...</>
    }
    return (
        <Card>
            <Title title="Create Reward"/>
            <CardContent>
                <Typography variant="h6" gutterBottom>Create Reward</Typography>

                <Form onSubmit={handleSubmit}>
                    <DialogContent>
                        <SelectInput optionText="name" choices={products} source="productId"  required="true"/>
                        <NumberInput
                            source="value"
                            required="true"
                            fullWidth
                            validate={(v) => v < 0}
                        />
                        <NumberInput
                            source="collateral"
                            required="true"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <SaveButton label="Create Reward"/>
                    </DialogActions>
                </Form>
            </CardContent>
        </Card>
    )
};
