import Chip from '@material-ui/core/Chip'
import { useRecordContext } from 'react-admin';
export const TextArrayField = ({ source }) => {
    const record = useRecordContext();
    const array = record[source]
    if (typeof array === 'undefined' || array === null || array.length === 0) {
        return <div/>
    } else {
        return (
            <>
                {array.map(item => <Chip label={item} key={item}/>)}
            </>
        )
    }
};
TextArrayField.defaultProps = { addLabel: true }