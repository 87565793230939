import {
    Datagrid,
    List,
    TextField,
    NumberField,
    DateField,
    BooleanField,
    Filter,
    TextInput,
    NumberInput,
    DateTimeInput,
    SelectInput,
} from "react-admin";
import * as React from "react";

export const NoTransferTransactionsFilter = props => (
    <Filter {...props}>
        <TextInput source="id" />
        <TextInput label="Account ID" source="accountId" />
        <TextInput source="userName" />
        <NumberInput source="value" />
        <SelectInput source="currency" choices={[
            { id: 'ETH', name: 'ETH' },
            { id: 'BTC', name: 'BTC' },
            { id: 'USD', name: 'USD' },
        ]}/>
        <NumberInput source="usdValue" />
        <TextInput source="transactionHash" />
        <TextInput source="wallet" />
        <NumberInput source="gascomission" />
        <DateTimeInput source="fromDate" />
        <DateTimeInput source="toDate" />
    </Filter>
);

export const NoTransferTransactionsList = props => (
    <List {...props} filters={<NoTransferTransactionsFilter/>} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <NumberField label="Value" source="value" options={{maximumFractionDigits: 20}} />
            <TextField label="Currency" source="currency" />
            <NumberField label="USD Value" source="usdValue" options={{maximumFractionDigits: 20}} />
            <TextField source="transactionHash" />
            <TextField source="wallet" />
            <NumberField source="gascomission" options={{maximumFractionDigits: 20}} />
            <DateField label="Date" source="timestamp" showTime />
            <DateField label="UpdatedDate" source="lastChangedDateTime" showTime />
            <BooleanField source="pending" />
        </Datagrid>
    </List>
);
