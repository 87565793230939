import {
    Datagrid,
    List,
    TextField,
    NumberField,
    DateField,
    BooleanField,
    Filter,
    TextInput, NumberInput, BooleanInput, DateTimeInput,
} from "react-admin";
import * as React from "react";

export const ContributionTransactionsFilter = props => (
    <Filter {...props}>
        <TextInput source="id" />
        <TextInput source="method" />
        <TextInput source="userName" />
        <TextInput source="productName" />
        <NumberInput source="value" />
        <TextInput source="currency" />
        <NumberInput source="usdValue" />
        <BooleanInput source="pending" />
        <DateTimeInput source="fromDate" />
        <DateTimeInput source="toDate" />
    </Filter>
);

export const ContributionTransactionsList = props => (
    <List {...props} filters={<ContributionTransactionsFilter/>} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="method" />
            <TextField label="User" source="user.userName" sortable={false} />
            <TextField label="Product" source="product.name" sortable={false} />
            <NumberField label="Value" source="value" options={{maximumFractionDigits: 20}} />
            <TextField label="Currency" source="currency" />
            <NumberField label="USD Value" source="usdValue" options={{maximumFractionDigits: 20}} />
            <DateField label="Date" source="timestamp" showTime />
            <DateField label="UpdatedDate" source="lastChangedDateTime" showTime />
            <BooleanField source="pending" />
        </Datagrid>
    </List>
);
