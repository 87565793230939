import {
    List,
    Filter,
    TextInput, NumberInput, BooleanInput, DateTimeInput,
} from "react-admin";
import {InvestmentTransactionsGrid} from "../components/investmentTransactionsGrid";
import { useState } from "react";

export const InvestmentTransactionsFilter = props => (
    <Filter {...props}>
        <TextInput source="id" />
        <TextInput source="method" />
        <TextInput source="userName" />
        <TextInput source="productName" />
        <NumberInput source="value" />
        <TextInput source="currency" />
        <NumberInput source="usdValue" />
        <NumberInput source="desinvested" />
        <NumberInput label="Product Value" source="prodValue" />
        <BooleanInput source="pending" />
        <DateTimeInput source="fromDate" />
        <DateTimeInput source="toDate" />
    </Filter>
);

export const InvestmentTransactionsList = props => {
    const [ refreshGrid, setRefreshGrid ] = useState(0);

    return (
    <List {...props} filters={<InvestmentTransactionsFilter/>} bulkActionButtons={false} key={refreshGrid}>
        <InvestmentTransactionsGrid setRefreshGrid={setRefreshGrid}/>
    </List>
)};