// in src/repositories/products.js
import * as React from "react";
import {
    Create,
    Datagrid,
    Edit,
    List,
    NumberField,
    SimpleForm,
    TextField,
    TextInput,
    NumberInput,
    DateTimeInput,
    SelectInput,
} from "react-admin";

export const ProductList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="description"/>
            <NumberField source="commission" options={{maximumFractionDigits: 20}}/>
            <NumberField source="minimumContribution" options={{maximumFractionDigits: 20}}/>
            <NumberField source="permanency" options={{maximumFractionDigits: 20}}/>
            <NumberField source="monthlyAverage" options={{maximumFractionDigits: 20}}/>
            <NumberField source="payment" options={{maximumFractionDigits: 20}}/>
            <NumberField source="licenseCost" options={{maximumFractionDigits: 20}}/>
            <TextField source="investmentCurrency"/>
        </Datagrid>
    </List>
);
export const ProductEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <TextInput source="description"/>
            <NumberInput source="commission"/>
            <NumberInput source="minimumContribution"/>
            <NumberInput source="permanency"/>
            <NumberInput source="monthlyAverage"/>
            <NumberInput source="payment"/>
            <NumberInput source="licenseCost"/>
            <DateTimeInput source="createDateTime" disabled/>
            <TextField source="createdBy" disabled/>
            <DateTimeInput source="lastChangedDateTime" disabled/>
            <TextField source="lastChangedBy" disabled/>
            <SelectInput source="investmentCurrency" choices={[
                { id: 'ETH', name: 'ETH' },
                { id: 'BTC', name: 'BTC' },
                { id: 'USD', name: 'USD' },
            ]}/>
            <SelectInput source="risk" choices={[
                {id: 'low', name: 'Low'},
                {id: 'medium', name: 'Medium'},
                {id: 'high', name: 'High'},
            ]}/>
            <TextInput source="termsAndConditions" multiline fullWidth/>
        </SimpleForm>
    </Edit>
);
export const ProductCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="name"/>
            <TextInput source="description"/>
            <NumberInput source="commission"/>
            <NumberInput source="minimumContribution"/>
            <NumberInput source="permanency"/>
            <NumberInput source="monthlyAverage"/>
            <NumberInput source="payment"/>
            <NumberInput source="licenseCost"/>
            <SelectInput source="investmentCurrency" choices={[
                { id: 'ETH', name: 'ETH' },
                { id: 'BTC', name: 'BTC' },
                { id: 'USD', name: 'USD' },
            ]}/>
            <SelectInput source="risk" choices={[
                {id: 'low', name: 'Low'},
                {id: 'medium', name: 'Medium'},
                {id: 'high', name: 'High'},
            ]}/>
            <TextInput source="termsAndConditions" multiline fullWidth/>
        </SimpleForm>
    </Create>
);
