import {authProvider} from "../auth/authProvider";

export const ApiClient = {
    get: async (url) => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const apiKey = process.env.REACT_APP_API_KEY;
        const token = authProvider.getToken();

        const request = new Request(`${baseUrl}${url}`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
                'Authorization': 'Bearer ' + token,
            }),
        });
        const res = await fetch(request);
        const response = await res.json();
        if (!res.ok) {
            throw new Error(response.message || 'Network error');
        }

        return response;
    },
    post: async (url, body) => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const apiKey = process.env.REACT_APP_API_KEY;
        const token = authProvider.getToken();

        const request = new Request(`${baseUrl}${url}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
                'Authorization': 'Bearer ' + token,
            }),
        });
        const res = await fetch(request);
        const responseBody = await res.json();
        if (!res.ok) {
            throw new Error(responseBody.message || 'Network error');
        }

        return responseBody;
    },
    patch: async (url, body) => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const apiKey = process.env.REACT_APP_API_KEY;
        const token = authProvider.getToken();

        const request = new Request(`${baseUrl}${url}`, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
                'Authorization': 'Bearer ' + token,
            }),
        });
        const res = await fetch(request);
        const responseBody = await res.json();
        if (!res.ok) {
            throw new Error(responseBody.message || 'Network error');
        }

        return responseBody;
    },
    delete: async (url) => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const apiKey = process.env.REACT_APP_API_KEY;
        const token = authProvider.getToken();

        const request = new Request(`${baseUrl}${url}`, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-api-key': apiKey,
                'Authorization': 'Bearer ' + token,
            }),
        });
        const res = await fetch(request);
        const responseBody = await res.json();
        if (!res.ok) {
            throw new Error(responseBody.message || 'Network error');
        }

        return responseBody;
    }
};