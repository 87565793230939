import {
    BooleanField,
    Datagrid,
    DateField,
    NumberField,
    TextField,
    useNotify
} from "react-admin";

import { UnfreezeButtons } from "./unfreezeButtons";

export const InvestmentTransactionsGrid = props => {
    const notify = useNotify();

    const unFreezeOk = () => {
        notify('Investment Transaction Accepted', 'success')
        props.setRefreshGrid(Math.random())
    };

    const unFreezeError = () => {
        notify('Error accepting Investment Transaction ', 'error')
    };

    const deleteOk = () => {
        notify('Investment Transaction Cancelled', 'success')
        props.setRefreshGrid(Math.random())
    };

    const deleteError = () => {
        notify('Error cancelling Investment Transaction ', 'error')
    };

    return (
        <Datagrid {...props}>
            <UnfreezeButtons 
                label="Status" 
                unFreezeOk={unFreezeOk} 
                unFreezeError={unFreezeError} 
                deleteOk={deleteOk} 
                deleteError={deleteError}
                />
            <BooleanField source="pending"/>
            <DateField label="Date" source="timestamp" showTime/>
            <TextField source="method"/>
            <TextField label="User" source="user.userName" sortable={false} />
            <TextField label="Product" source="product.name" sortable={false} />
            <NumberField label="Value" source="value" options={{maximumFractionDigits: 20}}/>
            <TextField label="Currency" source="currency"/>
            <NumberField label="USD Value" source="usdValue" options={{maximumFractionDigits: 20}}/>
            <NumberField label="Product Value" source="prodValue" options={{maximumFractionDigits: 20}}/>
            <TextField label="Investment Currency" source="investmentCurrency"/>
            <NumberField source="Divested" options={{maximumFractionDigits: 20}}/>
            <DateField label="Last Change" source="lastChangedDateTime" showTime />
            <TextField source="id"/>
        </Datagrid>
    )
};
