import {Datagrid, List, NumberField, TextField} from "react-admin";
import * as React from "react";

export const ProductContributionsList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Product" source="product.name"/>
            <NumberField label="totalBalance(ETH)" source="totalBalance" options={{maximumFractionDigits: 20}}/>
            <TextField label="Currency" source="currency" />
            <NumberField label="investmentFromLastReward" source="investmentFalseWeek" options={{maximumFractionDigits: 20}}/>
            <NumberField label="InvesmentWeek" source="blockedContributionWeek" options={{maximumFractionDigits: 20}}/>
            <NumberField label="Investment" source="investment" options={{maximumFractionDigits: 20}}/>
            <NumberField label="Investment + Blocked Contribution" source="investmentPlusBlockedContribution" options={{maximumFractionDigits: 20}}/>
            <NumberField label="Contribution + Blocked Contribution" source="contributionPlusBlockedContribution" options={{maximumFractionDigits: 20}}/>
        </Datagrid>
    </List>
);
