import {
  BooleanField,
  Datagrid,
  DateField,
  NumberField,
  TextField,
} from "react-admin";
import * as React from "react";

export const SwapTransactionsGrid = (props) => {
  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <TextField source="method" />
      <TextField
        label="User"
        source="productAccount.user.userName"
        sortable={false}
      />
      <TextField label="Product" source="product.name" sortable={false} />
      <NumberField
        label="Value"
        source="value"
        options={{ maximumFractionDigits: 20 }}
      />
      <TextField label="Currency" source="productAccount.investmentCurrency" />
      <NumberField
        label="USD Value"
        source="usdValue"
        options={{ maximumFractionDigits: 20 }}
      />
      <DateField label="Date" source="createDateTime" showTime />
      <BooleanField source="pending" />
      <BooleanField source="cancelled" />
    </Datagrid>
  );
};
