import React from 'react';
import {Button} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {ApiClient} from "../app/ApiClient";
import { useRecordContext } from 'react-admin';

export const UnfreezeButtons = props => {
    const record = useRecordContext();

    const handleUnfreeze = () => {
        const {unFreezeOk, unFreezeError} = props;
        unfreeze(record.id, unFreezeOk, unFreezeError);
    };

    const handleDelete = () => {
        const {deleteOk, deleteError} = props;
        deleteRecord(record.id, deleteOk, deleteError);
    };

    const unfreeze = async(transactionId, unFreezeOk, unFreezeError) => {
        try {
            await ApiClient.post('/investments/unfreeze/confirm', {
                transactionId: transactionId,
            });
            unFreezeOk();
        } catch (e) {
            unFreezeError();
        }
    }

    const deleteRecord = async(transactionId, deleteOk, deleteError) => {
        try {
            await ApiClient.delete('/investments/transactions/' + transactionId);
            deleteOk();
        } catch (e) {
            console.log("EX", e);
            deleteError();
        }
    }

    if (record.method === 'unfreeze' && record.pending) {
        return <div>
            <Button
                primary
                color="primary"
                onClick={handleUnfreeze}
            >
                <CheckIcon/> Accept
            </Button>
            <Button
                primary
                color="secondary"
                onClick={handleDelete}
            >
                <CloseIcon/> Cancel
            </Button>
        </div>
    } else {
        return <></>
    }
}

/*
class UnfreezeButtons extends Component {
    record = useRecordContext();
    
    handleUnfreeze = () => {
        const {record, unFreezeOk, unFreezeError} = this.props;
        this.unfreeze(record.id, unFreezeOk, unFreezeError);
    };

    handleDelete = () => {
        const {record, deleteOk, deleteError} = this.props;
        this.delete(record.id, deleteOk, deleteError);
    };

    async unfreeze(transactionId, unFreezeOk, unFreezeError) {
        try {
            await ApiClient.post('/investments/unfreeze/confirm', {
                transactionId: transactionId,
            });
            unFreezeOk();
        } catch (e) {
            unFreezeError();
        }
    }

    async delete(transactionId, deleteOk, deleteError) {
        try {
            await ApiClient.delete('/investments/transactions/' + transactionId);
            deleteOk();
        } catch (e) {
            console.log("EX", e);
            deleteError();
        }
    }

    render() {
        // const {record} = this.props;
        // if(!record) {
        //     return <></>
        // }
        if (record.method === 'unfreeze' && record.pending) {
            return <span>
                <Button
                    primary
                    color="primary"
                    onClick={this.handleUnfreeze}
                >
                    <CheckIcon/> Accept
                </Button>
                <Button
                    primary
                    color="secondary"
                    onClick={this.handleDelete}
                >
                    <CloseIcon/> Cancel
                </Button>
            </span>;
        } else {
            return <span/>;
        }
    }
}

export default UnfreezeButtons;
*/